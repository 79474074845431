<template>
  <div class="home-body home-4">
    <div class="wrapper">
      <!-- nav header -->
      <header class="header-area stick-h2">
        <div class="container-fluid">
          <div class="row">
            <div class="col-lg-12">
              <div class="logo floatleft">
                <a href="/">
                  <img
                    alt="PersonalPac - logo"
                    src="../assets/logo/personalpac-logo.svg"
                  />
                </a>
              </div>
              <div class="main-menu floatright">
                <nav>
                  <ul>
                    <li class="active remove-linkactive remove-link">
                      <a href="/">HOME</a>
                    </li>
                    <li>
                      <a class="remove-link" href="/about">ABOUT</a>
                    </li>
                    <li>
                      <a class="remove-link" href="/faq">FAQ</a>
                    </li>
                    <li>
                      <a class="remove-link" href="/contact">CONTACT</a>
                    </li>
                    <li>
                      <a class="remove-link" :href="registerUrl"
                        >CREATE AN ACCOUNT</a
                      >
                    </li>
                    <li>
                      <b-button
                        class="remove-link-button remove-link-button-gp"
                        :href="loginUrl"
                        >LOG IN</b-button
                      >
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </header>
      <!-- mobile nav menu -->
      <div class="mobile-menu-area d-block d-lg-none">
        <div class="container">
          <div class="mobile-menu-area">
            <div class="row">
              <div class="col-md-12">
                <div class="mobile-menu text-center py-3">
                  <nav>
                    <a class="active remove-link mr-3" href="/">HOME </a>
                    <a class="remove-link mr-3" href="/about">ABOUT </a>
                    <a class="remove-link mr-3" href="/faq">FAQ </a>
                    <a class="remove-link" href="/contact">CONTACT </a>
                  </nav>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <div class="mobile-menu text-center">
                  <nav>
                    <a class="remove-link" :href="registerUrl"
                      >CREATE AN ACCOUNT</a
                    >
                    <a
                      ><b-button
                        class="remove-link-button-mobile"
                        :href="loginUrl"
                        >LOG IN</b-button
                      ></a
                    >
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- mobile-menu-area end -->
      <!-- hero area -->
      <section class="slider-main-area">
        <div class="main-slider an-si">
          <div class="bend niceties preview-2">
            <!-- <div id="ensign-nivoslider-2" class="slides"> -->
            <div class="hero-slides">
              <!-- src="https://images.unsplash.com/photo-1617009723534-632d3eef4f1e?crop=entropy&cs=tinysrgb&fit=crop&fm=jpg&h=720&ixid=MnwxfDB8MXxyYW5kb218MHx8fHx8fHx8MTYzMDkyNjE3NQ&ixlib=rb-1.2.1&q=80&utm_campaign=api-credit&utm_medium=referral&utm_source=unsplash_source&w=1280" -->
              <!-- image will be loaded from the API -->
              <img
                id="heroImage"
                src=""
                class="main-image"
                alt=""
                title="#slider-direction-1"
              />
              <div class="main-container">
                <div class="inner-container container-xl">
                  <!-- text will be loader from the API -->
                  <div class="slide-all">
                    <!-- layer 2 -->
                    <div class="layer-2" id="heroTitle">
                      <!-- <h1 class="title6">PERSONALIZED ONLINE POLITICAL</h1> -->
                    </div>
                    <!-- layer 2 -->
                    <!-- <div class="layer-2">
                                        <h1 class="title6">ADS FOR <span>EVERY CITIZEN</span></h1>
                                    </div> -->
                    <!-- layer 1 -->
                    <div class="layer-1" id="heroSubtitle">
                      <!-- <h3 class="title5">A revolutionary new way to increase <br> engagement
                                            to your political campaigns.</h3> -->
                    </div>
                    <!-- layer 3 -->
                    <!-- <div class="layer-3">
                                        <a class="min1" href="#">Read more</a>
                                    </div> -->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <!-- what we do section -->
      <section class="what-area section-margin ptb-80">
        <div class="container">
          <div class="row">
            <div class="col-md-12 text-center">
              <div class="what-top">
                <div class="section-title">
                  <h1 class="main-heading">What We Do</h1>
                </div>
              </div>
            </div>
          </div>
          <div class="row justify-content-center">
            <div class="col-md-4 col-sm-6 text-center column">
              <div class="what-bottom what-hm-4">
                <div class="btn-icon">
                  <div class="then-icon">
                    <!-- <i class="fa fa-bullseye f-icon" aria-hidden="true"></i>F -->
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      class="iconColor f-icon"
                    >
                      <circle cx="12" cy="12" r="10" />
                      <circle cx="12" cy="12" r="6" />
                      <circle cx="12" cy="12" r="2" />
                    </svg>
                  </div>
                </div>
                <div id="companyMissionHeading" class="mission-text">
                  <!-- mission heading comes here -->
                </div>
                <div id="companyMissionText">
                  <!-- mission text comes here -->
                </div>
              </div>
            </div>
            <div class="col-md-4 col-sm-6 text-center column">
              <div class="what-bottom what-hm-4">
                <div class="btn-icon">
                  <div class="then-icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      class="f-icon"
                      :class="iconColor"
                    >
                      <circle cx="12" cy="8" r="7" />
                      <polyline
                        points="8.21 13.89 7 23 12 20 17 23 15.79 13.88"
                      />
                    </svg>
                  </div>
                </div>
                <div id="companyVisionHeading" class="mission-text">
                  <!-- vision heading comes here -->
                </div>
                <div id="companyVisionText">
                  <!-- vision text comes here -->
                </div>
              </div>
            </div>
            <div class="col-md-4 col-sm-6 text-center column">
              <div class="what-bottom what-hm-4">
                <div class="btn-icon">
                  <div class="then-icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      class="f-icon"
                      :class="iconColor"
                    >
                      <path
                        d="M21 16V8a2 2 0 0 0-1-1.73l-7-4a2 2 0 0 0-2 0l-7 4A2 2 0 0 0 3 8v8a2 2 0 0 0 1 1.73l7 4a2 2 0 0 0 2 0l7-4A2 2 0 0 0 21 16z"
                      />
                      <polyline points="7.5 4.21 12 6.81 16.5 4.21" />
                      <polyline points="7.5 19.79 7.5 14.6 3 12" />
                      <polyline points="21 12 16.5 14.6 16.5 19.79" />
                      <polyline points="3.27 6.96 12 12.01 20.73 6.96" />
                      <line x1="12" y1="22.08" x2="12" y2="12" />
                    </svg>
                  </div>
                </div>
                <div id="companyMethodologyHeading" class="mission-text">
                  <!-- methodology heading comes here -->
                </div>
                <div id="companyMethodologyText">
                  <!-- methodology text comes here -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <!-- video area -->
      <!-- <section class="video-area-section ptb-80">
            <div class="container">
                <div class="row">
                    <div
                        class="d-flex flex-column align-items-center align-items-lg-start justify-content-center px-3 px-lg-0 col-xl-6 col-lg-5 col-12">
                        <h3 class="text-center text-lg-start">HOW IT WORKS</h3>
                        <div class="home-down-text text-center text-lg-start">
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                                incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
                                exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. </p>
                            <p>Tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis
                                nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
                        </div>
                        <a class="demo-button-link" href="/contact">
                            Request a Demo
                        </a>
                    </div>
                    <div class="col-xl-6 col-lg-7 col-12">
                        <div class="video-border">
                            <div class="video-right overlay">
                                <div class="video-img">
                                    <img src="https://images.unsplash.com/photo-1588173926570-8f5dbece16aa?crop=entropy&cs=tinysrgb&fit=crop&fm=jpg&h=720&ixid=MnwxfDB8MXxyYW5kb218MHx8fHx8fHx8MTYzMTUxNjIzMw&ixlib=rb-1.2.1&q=80&utm_campaign=api-credit&utm_medium=referral&utm_source=unsplash_source&w=1280"
                                        alt="">
                                </div>
                                <div class="video-icon">
                                    <a href="https://www.youtube.com/watch?v=rouDIzhgVcY" class="video-popup"><i
                                            class="fa fa-play" aria-hidden="true"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section> -->
      <!-- about us section -->
      <section class="about-landing-area">
        <div class="container">
          <div class="row align-items-start justify-content-center">
            <div
              class="col-xl-8 col-lg-7 col-9 d-flex flex-column align-items-center video-con"
            >
              <div class="row">
                <div class="col-md-12 text-center">
                  <div class="what-top">
                    <div class="section-title">
                      <h1 class="main-heading video-heading">
                        SEE IT FOR YOURSELF
                      </h1>
                    </div>
                  </div>
                </div>
              </div>
              <!-- <H1 class="text-center text-lg-start text-white mb-4">SEE IT FOR YOURSELF</H1> -->
              <!-- <div class="video-border"> -->
              <!-- <div class="video-right overlay"> -->
              <div class="overlay">
                <div class="video-img">
                  <!-- placeholder image https://images.unsplash.com/photo-1588173926570-8f5dbece16aa?crop=entropy&cs=tinysrgb&fit=crop&fm=jpg&h=720&ixid=MnwxfDB8MXxyYW5kb218MHx8fHx8fHx8MTYzMTUxNjIzMw&ixlib=rb-1.2.1&q=80&utm_campaign=api-credit&utm_medium=referral&utm_source=unsplash_source&w=1280 -->
                  <img id="demoVideoPreviewImage" src="" alt="" />
                </div>
                <div class="video-icon">
                  <a href="" class="video-popup" id="demoVideo">
                    <i class="fa fa-play" aria-hidden="true"></i>
                  </a>
                </div>
                <!-- </div> -->
              </div>
              <a
                class="demo-button-link remove-link"
                :class="
                  env === 'personal-pac' ? ' background-pp' : 'background-gp'
                "
                href="/contact"
              >
                Request a Demo
              </a>
            </div>
            <!-- <div class="col-xl-9 col-md-8">
                        <div class="d-flex flex-column align-items-center justify-content-center about-landing-text">
                            <p class="text-center">
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                                incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
                                exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                           </p>
                            <a class="about-landing-button" href="">Want to know more?</a>
                        </div>
                    </div> -->
          </div>
        </div>
      </section>
      <!-- footer section -->
      <footer id="footer" class="social-area">
        <div class="footer-top">
          <div class="container-lg">
            <div class="row">
              <div
                class="d-flex flex-row align-items-center justify-content-start col-lg-6 col-md-6 col-sm-6 col-12"
              >
                <h4 class="social-follow-title">Follow Us</h4>
              </div>
              <div
                class="d-flex flex-row align-items-center justify-content-center justify-content-sm-end col-lg-6 col-md-6 col-sm-6 col-12"
              >
                <div class="social-icon floatright">
                  <ul>
                    <li>
                      <a
                        class="twitter"
                        target="_blank"
                        href="https://twitter.com/YourPersonalPAC"
                      >
                        <i class="fa fa-twitter-square" aria-hidden="true"></i>
                      </a>
                    </li>
                    <li>
                      <a
                        class="facebook"
                        target="_blank"
                        href="https://www.facebook.com/ThePersonalPACProject"
                      >
                        <i class="fa fa-facebook-square" aria-hidden="true"></i>
                      </a>
                    </li>
                    <li>
                      <a
                        class="youtube"
                        target="_blank"
                        href="https://www.youtube.com/channel/UCAuoNhsEtra6ACv8leeEexg"
                      >
                        <i class="fa fa-youtube-square" aria-hidden="true"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="footer-middle ptb-40">
          <div class="container-lg">
            <div class="row">
              <div class="col-lg-3 col-md-6">
                <div class="middle-text">
                  <div class="social-logo">
                    <a href="/">
                      <img
                        src="../assets/logo/personalpac-white-logo.svg"
                        alt=""
                      />
                    </a>
                  </div>
                  <div class="middle-mgn"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="footer-bottom">
          <div class="container-lg">
            <div class="footer-bottom-all">
              <div class="row">
                <div
                  class="d-flex flex-row align-items-center justify-content-center justify-content-sm-start col-12 col-sm-7 col-lg-6"
                >
                  <div class="footer-menu mt-2 mt-lg-0">
                    <ul>
                      <li>
                        <a
                          href="/"
                          class="active remove-link"
                          id="FOOTER-HOME"
                          >HOME</a
                        >
                      </li>
                      <li>
                        <a
                          href="/about"
                          class="remove-link"
                          id="FOOTER-ABOUT"
                          >ABOUT</a
                        >
                      </li>
                      <li>
                        <a
                          href="/faq"
                          class="remove-link"
                          id="FOOTER-FAQ"
                          >FAQ</a
                        >
                      </li>
                      <li>
                        <a
                          href="/contact"
                          class="remove-link"
                          id="FOOTER-CONTACT"
                          >CONTACT</a
                        >
                      </li>
                    </ul>
                  </div>
                </div>
                <div
                  class="d-flex flex-row align-items-center justify-content-center justify-content-sm-end col-12 col-sm-5 col-lg-6"
                >
                  <div class="footer-menu right-side-menu">
                    <ul>
                      <li>
                        <a
                          href="/privacy"
                          class="remove-link"
                          id="FOOTER-PRIVACY"
                          >PRIVACY</a
                        >
                      </li>
                      <li>
                        <a
                          href="/terms"
                          class="remove-link"
                          id="FOOTER-TC"
                          >T&amp;C</a
                        >
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  </div>
</template>
<!-- jquery -->
<script src="js/vendor/jquery-1.12.4.min.js"></script>
<!-- Popper JS -->
<script src="js/popper.js"></script>
<!-- bootstrap JS -->
<script src="js/bootstrap.min.js"></script>
<!-- wow JS -->
<script src="js/wow.min.js"></script>
<!-- plugins JS -->
<script src="js/plugins.js"></script>
<!-- main JS -->
<script src="js/main.js"></script>
<!-- custom JS -->
<script>
import "@/assets/styles/style.css";
import "@/assets/styles/animate.css";
import "@/assets/styles/bootstrap.min.css";
// import "@/assets/styles/chosen.min.css";
import "@/assets/styles/font-awesome.min.css";
import "@/assets/styles/magnific-popup.css";
import "@/assets/styles/main.css";
import "@/assets/styles/meanmenu.min.css";
import "@/assets/styles/normalize.css";
import "@/assets/styles/responsive.css";
// import "@/assets/styles/style-customizer.css";
import "@/assets/styles/color-custom.css";

export default {
  data() {
    return {
      env: null,
      iconColor: "",
      registerUrl: process.env.VUE_APP_CAMPAIGN_LOGIN_URL,
      loginUrl: process.env.VUE_APP_CAMPAIGN_REGISTER_URL,
    };
  },
};
function createNode(element) {
  return document.createElement(element);
}

function append(parent, el) {
  return parent.appendChild(el);
}

// const heroTitleEl = document.getElementById("heroTitle");
// const heroSubtitleEl = document.getElementById("heroSubtitle");
// const heroImageEl = document.getElementById("heroImage");
// const companyMissionHeadingEl = document.getElementById(
//   "companyMissionHeading"
// );
// const companyMissionTextEl = document.getElementById("companyMissionText");
// const companyVisionHeadingEl = document.getElementById("companyVisionHeading");
// const companyVisionTextEl = document.getElementById("companyVisionText");
// const companyMethodologyHeadingEl = document.getElementById(
//   "companyMethodologyHeading"
// );
// const companyMethodologyTextEl = document.getElementById(
//   "companyMethodologyText"
// );
// const demoVideoEl = document.getElementById("demoVideo");
// const demoVideoPreviewImageEl = document.getElementById(
//   "demoVideoPreviewImage"
// );

const heroContentUrl = `${process.env.VUE_APP_API_URL}/content/heroBody`;
const whatWeDoNextUrl = `${process.env.VUE_APP_API_URL}/content/whatWeDoNext`;
const demoVideoUrl = `${process.env.VUE_APP_API_URL}/content/demoVideo`;

fetch(heroContentUrl)
  .then((resp) => resp.json())
  .then(function (data) {
    if (data.code == 200 && data.message == "success") {
      // API result
      let result = data.content;

      let titleTemp = createNode("h1");
      titleTemp.className = "title6";
      titleTemp.innerHTML = result.heroTitle;

      let subtitleTemp = createNode("h3");
      subtitleTemp.className = "title5";
      subtitleTemp.innerHTML = result.heroSubTitle;

      append(document.getElementById("heroTitle"), titleTemp);
      append(document.getElementById("heroSubtitle"), subtitleTemp);

      document
        .getElementById("heroImage")
        .setAttribute("src", result.heroImage);
    }
  });

fetch(whatWeDoNextUrl)
  .then((resp) => resp.json())
  .then(function (data) {
    if (data.code == 200 && data.message == "success") {
      // API result
      let result = data.content;

      // mission heading & text
      let missionHeading = createNode("h3");
      missionHeading.innerHTML = result.companyMissionHeading;

      let missionText = createNode("p");
      missionText.className = "what-bottom-paragraph";
      missionText.innerHTML = result.companyMissionText;

      // vision heading & text
      let visionHeading = createNode("h3");
      visionHeading.innerHTML = result.companyVisionHeading;

      let visionText = createNode("p");
      visionText.className = "what-bottom-paragraph";
      visionText.innerHTML = result.companyVisionText;

      // vision heading & text
      let methodologyHeading = createNode("h3");
      methodologyHeading.innerHTML = result.companyMethodologyHeading;

      let methodologyText = createNode("p");
      methodologyText.className = "what-bottom-paragraph";
      methodologyText.innerHTML = result.companyMethodologyText;

      append(document.getElementById("companyMissionHeading"), missionHeading);
      append(document.getElementById("companyMissionText"), missionText);
      append(document.getElementById("companyVisionHeading"), visionHeading);
      append(document.getElementById("companyVisionText"), visionText);
      append(
        document.getElementById("companyMethodologyHeading"),
        methodologyHeading
      );
      append(
        document.getElementById("companyMethodologyText"),
        methodologyText
      );
    }
  });

fetch(demoVideoUrl)
  .then((resp) => resp.json())
  .then(function (data) {
    if (data.code == 200 && data.message == "success") {
      // API result
      let result = data.content;

      document
        .getElementById("demoVideo")
        .setAttribute("href", result.videoLink);
      document
        .getElementById("demoVideoPreviewImage")
        .setAttribute("src", result.previewImageLink);
    }
  });
</script>
<style scoped>
.logo-size {
  object-fit: cover;
  height: 200px;
}
.row:after {
  content: "";
  display: table;
  clear: both;
}

.demo-button-link {
  background-color: var(--primary);;
}
.demo-button-link:hover {
  background-color: var(--dark);
  color:  var(--primary);;
}

.remove-link {
  text-decoration: none;
  color: white;
  border: none !important;
}

.remove-link-button-gp {
  background-color: #b22234;
}

.remove-link-button-gp:hover {
  color: #b22234 !important;
}

.remove-link-button-pp {
  background-color: var(--primary);
}

.remove-link-button {
  width: 100px;
  border: inherit;
  border-radius: 4px;
  color: var(--white);
  margin-top: -5px;
}
.remove-link-button:hover {
  background-color: var(--dark);
}

.remove-link-button-mobile {
  background-color: var(--dark);
  width: 100px;
  border: inherit;
  border-radius: 4px;
  color: var(--white);
  margin-left: 20px;
}
.mobile-menu {
  height: 60px;
}
.active {
  color: black;
  text-decoration: underline;
}
</style>
